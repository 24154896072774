import styles from '../css/main.scss';
import "../js/bootstrap";
import "../js/fontawesome";

import ExternalLinks  from "./externallinks";
import PageTimer from "./pagetimer";


(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();


let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {
    import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
        .then((Magnific) => {
            let magnific =  new Magnific.default(popups);
            magnific.initialize();
        });
}

let forms = document.getElementsByClassName('needs-validation');
if(forms.length) {

    import(/* webpackChunkName: "forms" */ '../js/form')
        .then((Forms) => {
            let form =  new Forms.default(forms);
            form.submitButton().validate()
        })
}

let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {
    import(/* webpackChunkName: "share" */ '../js/share')
        .then((ShareHandler) => {
            for (let i = 0; i < shares.length; i++) {
                const share = shares[i];
                (new ShareHandler.default(share)).initialize();
            }
        })
}

if(document.querySelectorAll('[data-mh]').length) {
    import(/* webpackChunkName: "matchheight" */ 'matchheight')
        .then((m) => {
            console.log('Loaded, matching height!');
            m.default.init();
        })
}

let glides = document.getElementsByClassName('glide');
let lazyImages = document.getElementsByClassName('lazyload').length;

const fireGlider = () => {
    import(/* webpackChunkName: "glider" */ '../js/glide')
        .then((Glider) => {
            console.log('Glider loaded')
            let glide = new Glider.default(glides);
            glide.initialize();
        });
};

if (glides.length && !lazyImages) { fireGlider(); }

if(document.querySelectorAll('.lazyload').length) {
    import(/* webpackChunkName: "lazysizes" */ '../js/lazysizes')
        .then(() => {
            // wait for lazysizes before firing glider
            console.log('loaded lazysizes!');
            if (glides.length) {
                fireGlider();
            }
        })
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}